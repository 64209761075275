/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment, SyntheticEvent, useEffect, useMemo, useReducer, useState } from 'react'
import { locale } from 'primereact/api'
import { EventBusNotify, EventBusSubscribe, LocaleOptions, MainProps, RangePreset, TimeReducerPayload } from '../IWidgetProps'
import { getPureDate, timeSelectorValidate } from '../utils'
import { useCalendarProps } from '../hooks/useCalendarProps'
import { useObserver } from '../hooks/useObserver'
import { useLocale } from '../hooks/useLocale'
import { useIsFirstRender } from '../hooks/useIsFirstRender'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Calendar } from './Calendar'
import { TimeFields } from './TimeFields'
import { DaysSelector } from './DaysSelector'
import { DaysPresets } from './DaysPresets'
import { Footer } from './Footer'
import { name } from '../../package.json'
import { PublishPayload } from '@netvision/lib-types-frontend'

const timeReducer = (state: number[][], payload: TimeReducerPayload) => {
  if (Array.isArray(payload)) {
    return payload
  } else {
    const { part, index, value } = payload
    return [
      ...state.slice(0, part),
      [
        ...state[part].slice(0, index),
        value,
        ...state[part].slice(index + 1)
      ],
      ...state.slice(part + 1)
    ]
  }
}

export const Main = ({ style }: MainProps) => {
  const isFirst = useIsFirstRender()
  const { staticConfig, isClosable, timeSelector, dateSelector, initialDateTime, currentMode, rangePresets } = useCalendarProps()
  const { eventBus, eventBusID } = useObserver()
  const { $t, currentLocale } = useLocale()
  const [isReset, setReset] = useState(false)
  const [isCalendarActive, setIsCalendarActive] = useState(false)
  const [dateValue, setDateValue] = useState<Date[]>([])
  const [timeValue, setTimeValue] = useReducer(timeReducer, [[0, 0, 0], [23, 59, 59]])
  const [rangePeriod, setRangePeriod] = useState<RangePreset | null>(null)
  const primeLocales = locale(currentLocale).options as LocaleOptions

  const isTimeSelectorValid = useMemo(() => (
    timeSelector && timeSelectorValidate(timeSelector)
  ), [timeSelector])

  const finalValue = useMemo(() => {
    const preparedDateValue = dateValue.length === 1
      ? [dateValue[0], dateValue[0]]
      : dateValue

    const resultedDateValue = preparedDateValue.map((date, index) => {
      return getPureDate(date).getTime() +
        (timeValue[index][0] * 3.6e+6) +
        (Boolean(timeSelector?.fields?.includes('mm')) ? (timeValue[index][1] * 60000) : index === 0 ? 0 : 59 * 60000) +
        (Boolean(timeSelector?.fields?.includes('ss')) ? (timeValue[index][2] * 1000) : index === 0 ? 0 : 59 * 1000)
    })

    return resultedDateValue
  }, [dateValue, timeValue])

  const inputValue = useMemo(() => {
    if (!dateValue.length) return ''
    return finalValue.reduce((acc, next, index) => {
      const dateStr = new Date(next).toLocaleString(currentLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })

      if (acc !== dateStr) {
        if (index === 1) acc += ' - '
        acc += dateStr
      }

      return acc
    }, '')
  }, [finalValue])

  const hideCalendar = () => {
    eventBusID && eventBus.notify(eventBusID, EventBusNotify.hideCalendar, {
      publisher: name,
      occurrenceTime: Date.now(),
      data: null
    })
    setIsCalendarActive(false)
  }

  const parseTimestampsToDateAndTime = (timestamps: number[]) => {
    return timestamps.map((ts) => {
      const date = new Date(ts)
      const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getSeconds()
      return {
        date: dateOnly.getTime(),
        hours,
        minutes,
        seconds
      }
    })
  }

  const submitFormFilter = (e?: SyntheticEvent) => {
    e && e.preventDefault()
    eventBusID && eventBus.notify(eventBusID, EventBusNotify.applyDateTime, {
      publisher: name,
      occurrenceTime: Date.now(),
      data: {
        mode: currentMode,
        values: finalValue,
        preset: rangePeriod
      }
    })
    setIsCalendarActive(false)
    setReset(false)
  }

  const applyDateTime = (payload: PublishPayload<[number, number]>) => {
    if (payload.data.every((ts) => ts === 0)) {
      setDateValue([])
      setTimeValue([[0, 0, 0], [23, 59, 59]])
    } else {
      const parsedValues = parseTimestampsToDateAndTime(payload.data)
      setDateValue(parsedValues.map(({ date }) => new Date(date)))
      setTimeValue(parsedValues.map(({ hours, minutes, seconds }) => (
        [hours, minutes, seconds]
      )) as TimeReducerPayload)
    }
  }

  useEffect(() => {
    const [from, to] = finalValue
    if (from && to && from > to) {
      const { hours, minutes, seconds } = parseTimestampsToDateAndTime([from])[0]
      setTimeValue([timeValue[0] as [number, number, number], [hours, minutes, seconds]])
    }
  }, [finalValue])

  useEffect(() => {
    if (timeSelector && !timeSelectorValidate(timeSelector)) {
      console.error($t('_invalidTimeConfig'))
    }
  }, [timeSelector, $t])

  useEffect(() => {
    if (initialDateTime) {
      const dateTimeArray = Array.isArray(initialDateTime) ? initialDateTime : [initialDateTime, initialDateTime]
      const parsedValues = parseTimestampsToDateAndTime(
        dateTimeArray.map((el) => new Date(el).getTime())
      )
      if (parsedValues.length) {
        setDateValue(parsedValues.map(({ date }) => new Date(date)))
        setTimeValue(parsedValues.map(({ hours, minutes, seconds }) => (
          [hours, minutes, seconds]
        )) as TimeReducerPayload)
      }
    }
  }, [initialDateTime])

  useEffect(() => {
    !isFirst && setDateValue([])
  }, [currentMode])

  useEffect(() => {
    isReset && submitFormFilter()
  }, [isReset])

  useEffect(() => {
    setIsCalendarActive(Boolean(staticConfig?.inline))
    // @ts-ignore
    eventBus.subscribe(eventBusID, EventBusSubscribe.dateTimeUpdated, applyDateTime)
    eventBus.subscribe(eventBusID, EventBusSubscribe.showCalendar, ()=>{
      setIsCalendarActive(true)
    })
    return () => {
      eventBus.unsubscribe(eventBusID, EventBusSubscribe.dateTimeUpdated)
      eventBus.unsubscribe(eventBusID, EventBusSubscribe.showCalendar)
    }
  }, [])

  return (
    <Fragment>
      {(!staticConfig || !('inline' in staticConfig) || !staticConfig.inline) &&
        <div className="p-input-icon-right" css={dateInputCSS}>
          <i className="mdi mdi-20px mdi-calendar-text" />
          <InputText
            value={inputValue}
            readOnly={true}
            style={{ width: '100%' }}
            placeholder={$t('_mainInputPlaceholder')}
            onFocus={() => setIsCalendarActive(true)}
          />
        </div>
      }
      {isCalendarActive &&
        <form
          css={dateFilterFormCSS}
          onSubmit={submitFormFilter}
          className="w-calendar"
          style={style}
        >
          {isClosable &&
            <Button
              icon="mdi mdi-close"
              type="button"
              className="p-button-text p-button-secondary"
              css={closeButtonCSS}
              onClick={() => hideCalendar()}
            />
          }
          {primeLocales &&
            <Fragment>
              <div style={{ position: 'relative' }}>
                <Calendar
                  dateValue={dateValue}
                  setDateValue={setDateValue}
                />
              </div>
              {timeSelector && isTimeSelectorValid &&
                <TimeFields
                  isDisabled={dateValue.length === 0}
                  timeSelector={timeSelector}
                  timeValue={timeValue}
                  dateValue={dateValue}
                  currentMode={currentMode}
                  setTimeValue={setTimeValue}
                  submit={submitFormFilter}
                />
              }
              {!dateSelector?.simple && !rangePresets &&
                <DaysSelector
                  primeLocales={primeLocales}
                  dateValue={dateValue}
                  setDateValue={setDateValue}
                />
              }
              {rangePresets &&
                <DaysPresets
                  presets={rangePresets}
                  dateValue={dateValue}
                  setDateValue={setDateValue}
                  setRangePeriod={setRangePeriod}
                />
              }
              <Footer
                isDisabled={dateValue.length === 0}
                primeLocales={primeLocales}
                cleanDates={() => {
                  setDateValue([])
                  setTimeValue([[0, 0, 0], [23, 59, 59]])
                }}
                resetTimeToDefault={() => {
                  setTimeValue([[0, 0, 0], [23, 59, 59]])
                }}
                reset={() => {
                  setDateValue([])
                  setTimeValue([[0, 0, 0], [23, 59, 59]])
                  setReset(true)
                }}
              />
            </Fragment>
          }
        </form>
      }
    </Fragment>
  )
}

const dateInputCSS = css`
  display: block;
`

const dateFilterFormCSS = css`
  position: relative;
  padding-top: var(--spacer-xs);
`

const closeButtonCSS = css`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  font-size: var(--font-size-h2);
`
