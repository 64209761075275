export const localesEN = {
  _apply: 'Apply',
  _clean: 'Clean',
  _reset: 'Reset all',
  _timeReset: 'Reset time',
  _mainInputPlaceholder: 'Datetime filter',
  _dateTimeFilterModes: {
    single: 'Single',
    multiple: 'Multiple',
    range: 'Range'
  },
  _dateTimeLocale: {
    firstDayOfWeek: 0,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    toggleButtons: {
      month: 'Month',
      week: 'Week',
      dayBefore: 'Yesterday',
      today: 'Today'
    },
    tooltips: {
      mode: 'Input mode'
    }
  },
  _rangePresetsPlaceholder: 'Select period',
  _rangePresets: {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    thisMonth: 'This month',
    thisQuarter: 'This quarter',
    thisYear: 'This year',
    last7Days: 'Last 7 days',
    last30Days: 'Last 30 days',
    lastWeek: 'Last week',
    lastMonth: 'Last month',
    lastQuarter: 'Last quarter',
    lastYear: 'Last year'
  },
  _invalidTimeConfig: 'Time selector config is invalid. Required fields \'mode\' or \'fields\' are missing',
  _noEventBusMessage: '\'eventBusID\' property is required but has not been passed',
  _invalidModeToggler: '\'modeToggler\' property is invalid'
}
