import {
  startOfToday,
  endOfToday,
  endOfYesterday,
  startOfYesterday,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  addDays,
  addMonths,
  addQuarters,
  addYears
} from 'date-fns'
import {
  CalendarModes,
  DateSelectorValues,
  RangePreset,
  TimeSelector,
  TimeSelectorModes
} from './IWidgetProps'
import { ru } from 'date-fns/locale'
import { isEqual } from 'lodash-es'

interface PeriodStrategy {
  checkPeriod(dates: Date[]): boolean
}

export const dateSelectorSet = new Set(['today', 'yesterday', 'tomorrow'])
export const timeSelectorValues = new Set(['single', 'pair', 'hh', 'mm', 'ss'])
export const modeTogglerValues = new Set<CalendarModes>(['single', 'multiple', 'range'])

export const defineDate = (param?: DateSelectorValues) => {
  if (!param) return undefined
  switch(param) {
    case 'today':
      return new Date()
    case 'yesterday':
      return new Date(new Date().setDate(new Date().getDate() - 1))
    case 'tomorrow':
      return new Date(new Date().setDate(new Date().getDate() + 1))
    default:
      return new Date(param)
  }
}

export const timeSelectorValidate = (timeSelector: TimeSelector) => {
  const isFieldsValid = (
    Array.isArray(timeSelector.fields) &&
    timeSelector.fields.every((field) => field && timeSelectorValues.has(field))
  )
  const isModeValid = timeSelector.mode && timeSelectorValues.has(timeSelector.mode as TimeSelectorModes)
  return isFieldsValid && isModeValid
}

export const modeTogglerValidate = (modeToggler: readonly CalendarModes[]) => {
  return modeToggler.every((mode) => modeTogglerValues.has(mode))
}

export const getPureDate = (value: number | string | Date = new Date()) => {
  if (!(value instanceof Date)) {
    value = new Date(value)
  }
  value.setHours(0, 0, 0, 0)
  return value
}

export const purifyDatesArr = (arr: Date[]) => {
  return arr.map((date) => getPureDate(date))
}

export const PERIODS: readonly RangePreset[] = [
  'today',
  'yesterday',
  'thisWeek',
  'thisMonth',
  'thisQuarter',
  'thisYear',
  'last7Days',
  'last30Days',
  'lastWeek',
  'lastMonth',
  'lastQuarter',
  'lastYear',
] as const

const PERIODS_GENERATORS = {
  today: () => [startOfToday(), endOfToday()],
  yesterday: () => [startOfYesterday(), endOfYesterday()],
  thisWeek: () => [
    startOfWeek(new Date(), { locale: ru }),
    endOfWeek(new Date(), { locale: ru }),
  ],
  thisMonth: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  thisQuarter: () => [startOfQuarter(new Date()), endOfQuarter(new Date())],
  thisYear: () => [startOfYear(new Date()), endOfYear(new Date())],
  last7Days: () => [addDays(new Date(), -7), new Date()],
  last30Days: () => [addDays(new Date(), -30), new Date()],
  lastWeek: () => [
    startOfWeek(addDays(new Date(), -7), { locale: ru }),
    endOfWeek(addDays(new Date(), -7), { locale: ru }),
  ],
  lastMonth: () => [
    startOfMonth(addMonths(new Date(), -1)),
    endOfMonth(addMonths(new Date(), -1)),
  ],
  lastQuarter: () => [
    startOfQuarter(addQuarters(new Date(), -1)),
    endOfQuarter(addQuarters(new Date(), -1)),
  ],
  lastYear: () => [
    startOfYear(addYears(new Date(), -1)),
    endOfYear(addYears(new Date(), -1)),
  ],
} as Record<typeof PERIODS[number], () => Date[]>

export const periodsToDates = (period: typeof PERIODS[number]): Date[] => (
  PERIODS_GENERATORS[period]()
)

class TodayPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(getPureDate(dates[0]), startOfToday());
  }
}

class YesterdayPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(getPureDate(dates[0]), startOfYesterday());
  }
}

class ThisWeekPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.thisWeek()));
  }
}

class ThisMonthPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.thisMonth()));
  }
}

class ThisQuarterPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.thisQuarter()));
  }
}

class ThisYearPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.thisYear()));
  }
}

class Last7DaysPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.last7Days()));
  }
}

class Last30DaysPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.last30Days()));
  }
}

class LastWeekPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.lastWeek()));
  }
}

class LastMonthPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.lastMonth()));
  }
}

class LastQuarterPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.lastQuarter()));
  }
}

class LastYearPeriodStrategy implements PeriodStrategy {
  checkPeriod(dates: Date[]): boolean {
    return isEqual(purifyDatesArr(dates), purifyDatesArr(PERIODS_GENERATORS.lastYear()));
  }
}

const strategies: Record<typeof PERIODS[number], PeriodStrategy> = {
  today: new TodayPeriodStrategy(),
  yesterday: new YesterdayPeriodStrategy(),
  thisWeek: new ThisWeekPeriodStrategy(),
  thisMonth: new ThisMonthPeriodStrategy(),
  thisQuarter: new ThisQuarterPeriodStrategy(),
  thisYear: new ThisYearPeriodStrategy(),
  last7Days: new Last7DaysPeriodStrategy(),
  last30Days: new Last30DaysPeriodStrategy(),
  lastWeek: new LastWeekPeriodStrategy(),
  lastMonth: new LastMonthPeriodStrategy(),
  lastQuarter: new LastQuarterPeriodStrategy(),
  lastYear: new LastYearPeriodStrategy()
}

export const datesToPeriods = (dates: Date[]): typeof PERIODS[number] | void => {
  for (const period of Object.keys(strategies) as (keyof typeof strategies)[]) {
    if (strategies[period].checkPeriod(dates)) {
      return period;
    }
  }
};
