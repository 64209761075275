/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CalendarModes, MenuOptions, WidgetFooter } from '../IWidgetProps'
import { useLocale } from '../hooks/useLocale'
import { useCalendarProps } from '../hooks/useCalendarProps'
import { Button } from 'primereact/button'
import { Menu } from './Menu'

export const Footer = ({
  isDisabled,
  primeLocales,
  cleanDates,
  resetTimeToDefault,
  reset
}: WidgetFooter) => {
  const { $t } = useLocale()
  const { modeToggler, currentMode, setCurrentMode, timeSelector, dateSelector } = useCalendarProps()
  const [isModesSelectActive, setModesSelectState] = useState(false)

  const modesUI = new Map<CalendarModes, string>([
    ['single', 'mdi mdi-18px mdi-numeric-1-box-outline'],
    ['multiple', 'mdi mdi-hexagon-multiple-outline'],
    ['range', 'mdi mdi-18px mdi-calendar-range-outline']
  ])

  const modeOptions = useMemo(() => (
    modeToggler?.map<MenuOptions>((mode) => ({
      label: $t<string>(`_dateTimeFilterModes.${mode}`),
      key: mode,
      icon: modesUI.get(mode),
      isActive: mode === currentMode,
      command: () => {
        setCurrentMode(mode)
        setModesSelectState(false)
      }
    }))
  ), [modeToggler, currentMode])

  const docClickHandler = useCallback((e: MouseEvent) => {
    if ((e.target as Element)?.closest('#w-calendar-menu')) return
    setModesSelectState(false)
    document.removeEventListener('click', docClickHandler)
  }, [isModesSelectActive])

  useEffect(() => {
    if (isModesSelectActive) {
      document.addEventListener('click', docClickHandler)
    }

    return () => {
      document.removeEventListener('click', docClickHandler)
    }
  }, [isModesSelectActive, docClickHandler])

  return (
    <footer
      className="w-calendar__footer"
      css={footerCSS}
    >
      <Button
        type="submit"
        label={$t('_apply')}
        disabled={isDisabled}
        className={isDisabled ? 'p-button-outlined p-button-secondary w-calendar__submit' : ''}
      />
      {modeOptions && modeOptions.length > 1 &&
        <div className="w-calendar__mode" id="w-calendar-menu">
          {isModesSelectActive &&
            <Menu
              options={modeOptions}
              alignment={['right', 'top']}
            />
          }
          <Button
            type="button"
            tooltip={primeLocales.tooltips.mode}
            tooltipOptions={{ position: 'top', appendTo: 'self' }}
            className="p-button-outlined p-button-secondary"
            icon={!isModesSelectActive ? modesUI.get(currentMode) : 'mdi mdi-18px mdi-close'}
            onClick={() => setModesSelectState(!isModesSelectActive)}
          />
        </div>
      }
      <div className="w-calendar__clean">
        <Button
          type="button"
          tooltip={$t('_clean')}
          tooltipOptions={{ position: 'top', appendTo: 'self' }}
          icon="mdi mdi-18px mdi-filter-remove p-c"
          disabled={isDisabled}
          className={isDisabled ? 'p-button-outlined p-button-secondary' : ''}
          onClick={cleanDates}
        />
      </div>
      {timeSelector?.resetable &&
        <div className="w-calendar__clean">
          <Button
            type="button"
            tooltip={$t('_timeReset')}
            tooltipOptions={{ position: 'top', appendTo: 'self' }}
            icon="mdi mdi-18px mdi-clock-time-twelve-outline p-c"
            disabled={isDisabled}
            className={isDisabled ? 'p-button-outlined p-button-secondary' : ''}
            onClick={resetTimeToDefault}
          />
        </div>
      }
      {dateSelector?.resetable &&
        <div className="w-calendar__reset">
          <Button
            type="button"
            tooltip={$t('_reset')}
            tooltipOptions={{ position: 'top', appendTo: 'self' }}
            icon="mdi mdi-18px mdi-filter-off"
            disabled={isDisabled}
            className={isDisabled ? 'p-button-outlined p-button-secondary' : ''}
            onClick={reset}
          />
        </div>
      }
    </footer>
  )
}

const footerCSS = css`
  display: flex;
  margin-top: var(--spacer-sm);
  padding: 0 var(--spacer) var(--spacer);

  & > .p-button {
    margin-left: 0;

    &[type="submit"] {
      flex-grow: 1;
    }
  }

  .w-calendar__mode,
  .w-calendar__clean,
  .w-calendar__reset {
    position: relative;
    margin-left: calc(10rem / var(--bfs));

    .p-menu {
      position: absolute;
      bottom: 100%;
      top: auto !important;
      left: auto !important;
      right: 0 !important;
      padding: 0;

      .p-menu-list > li {
        width: auto;
      }
    }

    .p-tooltip {
      position: absolute;
      bottom: 100%;
      top: auto !important;
      left: auto !important;
      right: 0 !important;
      width: max-content;
    }
  }
`
