import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { CalendarConfig, CalendarProvider } from '../IWidgetProps'
import { modeTogglerValidate } from '../utils'
import { useLocale } from './useLocale'

const CalendarPropsContext = React.createContext<CalendarProvider>(null!)

export const useCalendarProps = () => useContext(CalendarPropsContext)

export const CalendarPropsProvider: FC<{ data: CalendarConfig, children: ReactNode }> = ({ data, children }) => {
  const { $t } = useLocale()
  const [currentMode, setCurrentMode] = useState(data.selectionMode || data.modeToggler?.[0] || 'single')

  useEffect(() => {
    if (data.modeToggler && !modeTogglerValidate(data.modeToggler)) {
      throw new Error($t('_invalidModeToggler'))
    }
  }, [data.modeToggler])

  return (
    <CalendarPropsContext.Provider value={{ ...data, currentMode, setCurrentMode }}>
      {children}
    </CalendarPropsContext.Provider>
  )
}
