
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment, useCallback } from 'react'
import { useCalendarProps } from '../hooks/useCalendarProps'
import { ToggleButton } from 'primereact/togglebutton'
import { DaysSelectorProps } from '../IWidgetProps'
import { getPureDate } from '../utils'

export const DaysSelector = ({ primeLocales, dateValue, setDateValue }: DaysSelectorProps) => {
  const { currentMode } = useCalendarProps()

  const isOffsetMatched = useCallback((day: number) => {
    if (!dateValue.length) return false
    const today = getPureDate()
    const nDaysAgo = new Date(today.valueOf() - day * 86400000)

    return dateValue.every((timestamp) => {
      const date = getPureDate(timestamp)
      return date.valueOf() === nDaysAgo.valueOf()
    })

  }, [dateValue])

  const isRangeMatched = useCallback((days: number) => {
    if (!dateValue.length) return false
    const currentDate = getPureDate()
    const targetDateTimestamp = currentDate.getTime() - (days * 86400000)
    const firstPeriodDate = getPureDate(Math.min(...dateValue.map(date => date.getTime())))
    if (currentMode === 'multiple') {
      return days === dateValue.length - 1
        ? targetDateTimestamp === firstPeriodDate.getTime()
        : false
    }
    return (
      targetDateTimestamp === firstPeriodDate.getTime()
      && currentDate.getTime() === Math.max(...dateValue.map(date => date.getTime()))
    )
  }, [dateValue])

  const fillTimestampsOutOfRange = (from: number, to: number) => {
    const timestamps: number[] = []
    const currentDate = new Date(from)

    while (currentDate.getTime() <= to) {
      timestamps.push(currentDate.getTime())
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return timestamps.map((ts) => new Date(ts))
  }

  const setDateRange = (days: number) => {
    const interval = days * 8.64e+7
    const endRange = getPureDate()
    const endRangeTime = endRange.getTime()
    const startRangeTime = endRangeTime - interval
    setDateValue(
      currentMode === 'range'
        ? [new Date(startRangeTime), new Date(endRangeTime)]
        : fillTimestampsOutOfRange(startRangeTime, endRangeTime)
    )
  }

  const setDateOffset = (day: number) => {
    const currentDate = getPureDate()
    const targetDate = new Date(currentDate.setDate(currentDate.getDate() - day)).getTime()
    setDateValue([new Date(targetDate), new Date(targetDate)])
  }

  return (
    <div css={dateFilterActionRowsCSS}>
      {currentMode !== 'single' &&
        <Fragment>
          <ToggleButton
            type="button"
            onLabel={String(primeLocales.toggleButtons.month)}
            offLabel={String(primeLocales.toggleButtons.month)}
            checked={isRangeMatched(31)}
            onChange={() => setDateRange(31)}
          />
          <ToggleButton
            type="button"
            onLabel={String(primeLocales.toggleButtons.week)}
            offLabel={String(primeLocales.toggleButtons.week)}
            checked={isRangeMatched(7)}
            onChange={() => setDateRange(7)}
          />
        </Fragment>
      }
      <ToggleButton
        type="button"
        onLabel={String(primeLocales.toggleButtons.dayBefore)}
        offLabel={String(primeLocales.toggleButtons.dayBefore)}
        checked={isOffsetMatched(1)}
        onChange={() => setDateOffset(1)}
      />
      <ToggleButton
        type="button"
        onLabel={String(primeLocales.toggleButtons.today)}
        offLabel={String(primeLocales.toggleButtons.today)}
        checked={isOffsetMatched(0)}
        onChange={() => setDateOffset(0)}
      />
    </div>
  )
}

const dateFilterActionRowsCSS = css`
  display: grid;
  justify-content: center;
  gap: calc(10rem / var(--bfs));
  grid-auto-flow: column;
  padding: 0 var(--spacer);
`
