import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { Main } from './components/Main'
import { WidgetConfig } from './IWidgetProps'
import { MountProvider } from './hooks/useMount'
import { ObserverProvider } from './hooks/useObserver'
import { CalendarPropsProvider } from './hooks/useCalendarProps'
import { LocaleProvider } from './hooks/useLocale'

const MountPortal = ({ children, selector }: { children: ReactNode; selector?: string }) => {
  if (!selector) {
    return <>{children}</>
  }

  const mountElement = document.querySelector(selector)
  if (!mountElement) {
    throw new Error(`There is no element with selector "${selector}"`)
  }

  return createPortal(children, mountElement)
}

const Root = (config: WidgetConfig) => {
  const {
    areas,
    mountChildren,
    unmountSelf,
    props
  } = config
  return (
    <MountPortal selector={props.appendTo}>
      <LocaleProvider>
        <MountProvider data={{
          mountChildren,
          unmountSelf,
          areas
        }}>
          <ObserverProvider data={{
            observer: props.observer,
            eventBus: props.eventBus,
            observerID: props.observerID,
            eventBusID: props.eventBusID
          }}>
            <CalendarPropsProvider data={{
              isClosable: props.isClosable,
              modeToggler: props.modeToggler,
              rangePresets: props.rangePresets,
              presetValue: props.presetValue,
              dateSelector: props.dateSelector,
              timeSelector: props.timeSelector,
              staticConfig: props.staticConfig,
              initialDateTime: props.initialDateTime,
              selectionMode: props.selectionMode
            }}>
              <Main style={props.style} />
            </CalendarPropsProvider>
          </ObserverProvider>
        </MountProvider>
      </LocaleProvider>
    </MountPortal>
  )
}

export default Root
