import React, { FC, ReactNode, useContext } from 'react'
import { GlobalObserver, GlobalEventBus } from '@netvision/lib-types-frontend'
import { useLocale } from './useLocale'

type TObserverContextType = {
  observerID?: string
  eventBusID: string
  observer?: GlobalObserver
  eventBus: GlobalEventBus
}

const ObserverContext = React.createContext<TObserverContextType>(null!)

export const useObserver = () => useContext(ObserverContext)

export const ObserverProvider: FC<{ data: TObserverContextType, children: ReactNode }> = ({ data, children }) => {
  const { $t } = useLocale()

  if (!data.eventBusID) {
    throw new Error($t('_noEventBusMessage'))
  }
  return (
    <ObserverContext.Provider value={data}>
      {children}
    </ObserverContext.Provider>
  )
}
