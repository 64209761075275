/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useEffect, useMemo, useRef } from 'react'
import { CalendarChangeParams, Calendar as PrimeCalendar } from 'primereact/calendar'
import { useLocale } from '../hooks/useLocale'
import { useCalendarProps } from '../hooks/useCalendarProps'
import { CalendarProps } from '../IWidgetProps'
import { defineDate, getPureDate } from '../utils'

export const Calendar = ({ dateValue, setDateValue }: CalendarProps) => {
  const { currentLocale } = useLocale()
  const { dateSelector, staticConfig, currentMode } = useCalendarProps()
  const calendarRef = useRef<PrimeCalendar>(null!)

  const formatAndSetDateValue = (e: CalendarChangeParams) => {
    const newValue = Array.isArray(e.value)
      ? e.value.reduce<Date[]>((acc, next) => {
          if (next) {
            acc.push(getPureDate(next))
          }
          return acc
        }, [])
      : e.value
        ? [e.value, e.value].map((val) => getPureDate(val))
        : []

    setDateValue(newValue)
  }

  const definedSelectorDates = useMemo(() => ({
    minDate: defineDate(dateSelector?.minDate),
    maxDate: defineDate(dateSelector?.maxDate)
  }), [dateSelector])

  const yearRange = useMemo(() => {
    const minYear = definedSelectorDates.minDate?.getFullYear() || new Date().getFullYear() - 5
    const maxYear = definedSelectorDates.maxDate?.getFullYear() || new Date().getFullYear() + 4
    return `${minYear}:${maxYear}`
  }, [definedSelectorDates])

  useEffect(() => {
    const refValue = calendarRef.current.getViewDate()
    const refDate = Array.isArray(refValue) ? refValue[0] : refValue

    if (
      refDate.getMonth() !== dateValue[0]?.getMonth()
      || refDate.getFullYear() !== dateValue[0]?.getFullYear()
    ) calendarRef.current.updateViewDate(null, dateValue?.[0] || new Date())
  }, [dateValue])

  return (
    <PrimeCalendar
      { ...staticConfig }
      inline={true}
      ref={calendarRef}
      css={calendarCSS}
      locale={currentLocale}
      selectionMode={currentMode}
      minDate={definedSelectorDates.minDate}
      maxDate={definedSelectorDates.maxDate}
      onChange={formatAndSetDateValue}
      monthNavigator={true}
      yearNavigator={true}
      yearRange={yearRange}
      value={currentMode === 'single'
        ? new Date(Math.min(...dateValue.map(date => date.getTime())))
        : dateValue
      }
    />
  )
}

const calendarCSS = css`
  display: flex !important;

  .p-datepicker {
    padding: var(--spacer-xs) var(--spacer) 0 !important;
    width: 100% !important;

    &-title {
      display: flex;
    }

    table {
      margin: var(--spacer-xs) 0
    }

    .p-datepicker-title {
      color: var(--text-color-secondary);

      .p-link {
        font-size: var(--font-size-lg);
        color: inherit;
      }

      .p-datepicker-month,
      .p-datepicker-year {
        border-radius: 6px;
        border-color: transparent;
        background-color: transparent;
        cursor: pointer;
        color: var(--text-color-secondary);
        padding: 0.5rem 0.75rem;

        option {
          background: var(--surface-b);

          &:checked {
            background: var(--primary-color);
          }
        }
      }
    }

    .p-yearpicker {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      text-align: center;
      margin: var(--spacer) calc(var(--spacer-xs) * -1);

      .p-yearpicker-year {
        padding: calc(5rem / var(--bfs));
        transition: background-color var(--transition-duration), color var(--transition-duration), border-color var(--transition-duration), box-shadow var(--transition-duration);
        border-radius: var(--border-radius);

        &:not(.p-disabled) {
          cursor: pointer;

          &:hover {
            background: var(--secondary-color);
          }
        }
      }
    }
  }
`
