/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { MenuProps } from '../IWidgetProps'
import { useMemo } from 'react'

export const Menu = ({ options, alignment }: MenuProps) => {
  const position = useMemo(() => {
    const [x, y] = alignment
    return {
      [x]: 0,
      [y === 'top' ? 'bottom' : 'top']: '100%'
    }
  }, [alignment])

  return (
    <div css={menuCSS} style={position}>
      <ul
        className="w-calendar__menu"
        style={{ alignItems: alignment[0] === 'right' ? 'flex-end' : 'flex-start' }}
      >
        {options.map((option) => (
          <li
            key={option.key}
            className={`w-calendar__menu-item ${option.isActive ? '--active' : ''}`}
            onClick={option.command}
          >
            {option.icon &&
              <span className={`p-menuitem-icon mdi-18px ${option.icon}`}></span>
            }
            <span className="w-calendar__menu-label">{option.label}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

const menuCSS = css`
  position: absolute;

  .w-calendar {

    &__menu {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      
      &-item {
        padding: 0.75rem 1.25rem;
        display: flex;
        cursor: pointer;
        width: max-content;
        margin-bottom: calc(4rem / var(--bfs));
        background: var(--secondary-color-alt-2);
        padding: var(--input-padding-top) var(--input-padding-left);
        color: var(--text-color-secondary);
        border-radius: var(--border-radius);
        transition: background-color var(--transition-duration), color var(--transition-duration), border-color var(--transition-duration), box-shadow var(--transition-duration);
        user-select: none;
        font-size: calc(14rem / var(--bfs));
        border: calc(var(--input-border-width) * 2) solid transparent;

        .p-menuitem-icon {
          font-family: primeicons;
          font-style: normal;
          font-weight: 400;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          margin-right: calc(10rem / var(--bfs));
          -webkit-font-smoothing: antialiased;
        }

        &.--active {
          backdrop-filter: blur(15px);
          background: rgba(var(--primary-color-raw), 0.1) var(--selection-gradient);
          color: var(--text-color-active);
          border-color: var(--primary-color);
        }

        &:hover {
          background: var(--primary-color);
          color: var(--text-color-active);
        }
      }
    }
  }
`
