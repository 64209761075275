import { GlobalObserver, GlobalEventBus } from '@netvision/lib-types-frontend'
import { CSSProperties, Dispatch, SetStateAction } from 'react'
export type LocaleCodes = 'ru' | 'en'
export type TLocales = Record<LocaleCodes, Record<string, any>>

export type DateSelectorValues = 'today' | 'yesterday' | 'tomorrow' | number | Date
export type CalendarModes = 'multiple' | 'single' | 'range'
export type TimeSelectorModes = 'single' | 'pair'
export type PositionY = 'top' | 'bottom'
export type PositionX = 'left' | 'right'
export type TimeSelectorFields = 'hh' | 'mm' | 'ss'
export type RangePreset =
| 'today'
| 'yesterday'
| 'thisWeek'
| 'thisMonth'
| 'thisQuarter'
| 'thisYear'
| 'last7Days'
| 'last30Days'
| 'lastWeek'
| 'lastMonth'
| 'lastQuarter'
| 'lastYear'

export interface IWidgetChild {
  type: string
  src: string
  props: Record<string, unknown>
  areas: readonly IWidgetArea[]
}

export interface IWidgetArea {
  name: string
  children: readonly IWidgetChild[]
}

export interface DateSelector {
  minDate?: DateSelectorValues
  maxDate?: DateSelectorValues
  resetable?: boolean
  simple?: boolean
}

export interface TimeSelector {
  mode: TimeSelectorModes
  fields: readonly [TimeSelectorFields, TimeSelectorFields?, TimeSelectorFields?]
  resetable?: boolean
  minTime?: number
  maxTime?: number
}

export interface CalendarConfigStatic {
  selectionMode: CalendarModes
  inline: boolean
}

export interface CalendarConfig {
  isClosable?: boolean
  modeToggler?: readonly CalendarModes[]
  rangePresets?: readonly RangePreset[]
  selectionMode?: CalendarModes
  presetValue?: RangePreset
  dateSelector?: DateSelector
  timeSelector?: TimeSelector
  staticConfig?: CalendarConfigStatic
  initialDateTime?: number | string | Date | number[] | string[] | Date[]
}

export interface CalendarProvider extends CalendarConfig {
  currentMode: CalendarModes
  setCurrentMode: React.Dispatch<React.SetStateAction<CalendarModes>>
}

export interface WidgetProps extends CalendarConfig {
  observer: GlobalObserver
  eventBus: GlobalEventBus
  observerID?: string
  eventBusID: string
  parentName: string
  appendTo?: string
  selectionMode?: CalendarModes
  style?: CSSProperties
}

export enum EventBusNotify {
  hideCalendar = 'hideCalendar',
  applyDateTime = 'applyDateTime'
}  

export enum EventBusSubscribe {
  dateTimeUpdated = 'dateTimeUpdated',
  showCalendar = 'showCalendar'
}

export interface WidgetConfig {
  props: WidgetProps
  areas?: readonly IWidgetArea[]
  mountChildren: (domElement: HTMLElement, children: Array<IWidgetChild | Record<string, unknown>>) => () => void
  unmountSelf: () => void
}

export interface PublishPayloadData<T> {
  sortingField: string
  payload: T
}

export interface LocaleOptions {
  toggleButtons: {
    month: string
    week: string
    dayBefore: string
    today: string
  }
  tooltips: {
    mode: string
  }
}

export interface MainProps {
  style: WidgetProps['style']
}

export interface CalendarProps {
  dateValue: Date[]
  setDateValue: Dispatch<SetStateAction<Date[]>>
}

export interface WidgetFooter {
  isDisabled: boolean
  primeLocales: LocaleOptions
  cleanDates: () => void
  resetTimeToDefault: () => void
  reset: () => void
}

export interface MenuOptions {
  label: string
  key: string
  icon?: string
  isActive: boolean
  command: () => void
}

export interface MenuProps {
  options: MenuOptions[]
  alignment: [PositionX, PositionY]
}

export interface DaysSelectorProps {
  primeLocales: LocaleOptions
  dateValue: Date[]
  setDateValue: Dispatch<SetStateAction<Date[]>>
}

export interface DaysPresetsProps {
  presets: readonly RangePreset[]
  dateValue: Date[]
  setDateValue: Dispatch<SetStateAction<Date[]>>
  setRangePeriod: Dispatch<SetStateAction<RangePreset | null>>
}

export type TimeReducerPayload = |
  { part: number; index: number; value: number }|
  [[number, number, number], [number, number, number]]

export interface TimeSelectorProps {
  isDisabled: boolean
  timeSelector: TimeSelector
  timeValue: number[][]
  dateValue: Date[]
  currentMode: CalendarModes
  setTimeValue: Dispatch<TimeReducerPayload>
  submit: (e?: React.SyntheticEvent) => void
}

export interface TimeInputProps {
  value: number
  isFocused: boolean
  digits?: number
  range?: [number, number]
  setValue: (value: number) => void
  apply: () => void
  switchInput: () => void
  resetFocusState: () => void
}
