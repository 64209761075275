export const localesRU = {
  _apply: 'Применить',
  _clean: 'Очистить',
  _reset: 'Сбросить всё',
  _timeReset: 'Сбросить время',
  _mainInputPlaceholder: 'Фильтр по дате',
  _dateTimeFilterModes: {
    single: 'Одиночный',
    multiple: 'Множественный',
    range: 'Диапазон'
  },
  _dateTimeLocale: {
    firstDayOfWeek: 1,
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нов', 'Дек'],
    toggleButtons: {
      month: 'Месяц',
      week: 'Неделя',
      dayBefore: 'Вчера',
      today: 'Сегодня'
    },
    tooltips: {
      mode: 'Режим ввода'
    }
  },
  _rangePresetsPlaceholder: 'Выберите период',
  _rangePresets: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    thisWeek: 'Эта неделя',
    thisMonth: 'Этот месяц',
    thisQuarter: 'Этот квартал',
    thisYear: 'Этот год',
    last7Days: 'Последние 7 дней',
    last30Days: 'Последние 30 дней',
    lastWeek: 'Прошлая неделя',
    lastMonth: 'Прошлый месяц',
    lastQuarter: 'Прошлый квартал',
    lastYear: 'Прошлый год'
  },
  _invalidTimeConfig: 'Конфигурация селектора времени невалидна. Отсутствуют обязательные поля \'mode\' и/или \'fields\'',
  _noEventBusMessage: 'свойство \'eventBusID\' является обязательным, но не передано',
  _invalidModeToggler: 'Свойство \'modeToggler\' невалидно'
}
