/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TimeInputProps } from '../IWidgetProps'
import { Button } from 'primereact/button'

export const TimeInput = ({
  value,
  isFocused,
  digits,
  range,
  setValue,
  apply,
  switchInput,
  resetFocusState
}: TimeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [localValue, setLocalValue] = useState(value)
  const [isApplied, setIsApplied] = useState(false)

  const getValidatedValue = (val: number) => {
    if (isNaN(val)) return 0
    val = Math.abs(val)
    if (!range) return val
    const [minValue, maxValue] = range
    if (val > maxValue) return maxValue
    if (val < minValue) return minValue
    return val
  }

  const checkAndPassValue = () => {
    setValue(getValidatedValue(localValue))
    resetFocusState()
  }

  const keyboardEventHandlers = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.code) {
      case 'ArrowUp':
        e.preventDefault()
        if (localValue < (range?.[1] || 59)) {
          setLocalValue(getValidatedValue(localValue + 1))
        }
        break
      case 'ArrowDown':
        e.preventDefault()
        if (localValue > (range?.[0] || 0)) {
          setLocalValue(getValidatedValue(localValue - 1))
        }
        break
      case 'Tab':
        e.preventDefault()
        switchInput()
        break
      case 'Enter':
        e.preventDefault()
        checkAndPassValue()
        setIsApplied(true)
    }
  }

  const digitsLength = (val: number) => {
    return String(val).length || 1
  }

  const inputPrefix = useMemo(() => {
    const valueLength = digitsLength(localValue)
    return !digits
      ? '' : digits <= valueLength
      ? '' : '0'.repeat(digits - valueLength)
  }, [localValue, digits])

  const maxLength = useMemo(() => {
    return !range || inputPrefix ? Infinity : digitsLength(range[1])
  }, [range, inputPrefix])

  const selectByFocus = () => {
    inputRef.current?.select()
  }

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  useEffect(() => {
    if (isApplied) {
      apply()
      setIsApplied(false)
    }
  }, [isApplied])

  useEffect(() => {
    checkAndPassValue();
  }, [localValue]);

  useEffect(() => {
    isFocused && inputRef.current?.focus()
  }, [isFocused])

  return (
    <div css={timeInputCSS}>
      <Button
        type="button"
        icon="pi pi-chevron-up"
        className="p-button-sm p-button-text"
        disabled={range ? localValue === range[1] : false}
        onClick={() => setLocalValue(getValidatedValue(localValue + 1))}
      />
      <input
        size={1}
        type="text"
        ref={inputRef}
        step={1}
        inputMode="numeric"
        maxLength={maxLength}
        onFocus={selectByFocus}
        onClick={selectByFocus}
        onBlur={checkAndPassValue}
        value={inputPrefix + localValue}
        onKeyDown={keyboardEventHandlers}
        className="p-inputtext p-component p-inputnumber-input"
        onInput={(e) => setLocalValue(Number(e.currentTarget.value))}
      />
      <Button
        type="button"
        icon="pi pi-chevron-down"
        className="p-button-sm p-button-text"
        disabled={range ? localValue === range[0] : false}
        onClick={() => setLocalValue(getValidatedValue(localValue - 1))}
      />
    </div>
  )
}

const timeInputCSS = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .p-button {
    &:disabled {
      filter: brightness(0.5);
      opacity: 0.5;
    }
  }

  .p-inputnumber-input {
    text-align: center;
    background-color: transparent;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
